/**
 * BlockchainDailyCacheCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'

/* TODO: review generated class */
@HttpExclude()
export class BlockchainDailyCacheCollection extends PageCollection<
  BlockchainDailyCache
> {
  constructor() {
    super(BlockchainDailyCache)
  }

  resource?: IBlockchainDailyCacheCollectionResourcesHolder

  @RequestExpose() startDate: string | null = null
  @RequestExpose() endDate: string | null = null
  @RequestExpose() minContractCount: number | null = null
  @RequestExpose() maxContractCount: number | null = null
  @RequestExpose() minAddressCount: number | null = null
  @RequestExpose() maxAddressCount: number | null = null
  @RequestExpose() minTransactionCount: number | null = null
  @RequestExpose() maxTransactionCount: number | null = null

  queryAsPage() {
    return this.listBlockchainDailyCache()
  }

  async listBlockchainDailyCache() {
    return await Request.get(`/client/blockchain-daily-cache`, {
      params: this.params,
    })
      .name('listBlockchainDailyCache')
      .as(this)
      .getResponse()
  }

  async listExportBlockchainDailyCache() {
    return await Request.get(`/client/blockchain-daily-cache/export`, {
      params: this.params,
    })
      .name('listExportBlockchainDailyCache')
      .as(this)
      .getResponse()
  }
}

export interface IBlockchainDailyCacheCollectionResourcesHolder {}
