/**
 * Export Schema of BlockchainDailyCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'

/* TODO: review generated schema */
export class ExportBlockchainDailyCacheSchema extends DefaultSchema {
  readonly name = 'ExportBlockchainDailyCache'

  readonly fieldSet: FieldSet<BlockchainDailyCache> = {
    idBlockchainDailyCachePk: schema => schema.model.idBlockchainDailyCachePk,
    contractCount: schema => schema.model.contractCount,
    addressCount: schema => schema.model.addressCount,
    transactionCount: schema => schema.model.transactionCount,
    date: schema => $.filter.datetime(schema.model.date),
  }
}
