/**
 * BlockchainDailyCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {BlockchainDailyCacheCollection} from '@/model/collection/BlockchainDailyCacheCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

/* TODO: review generated class */
export class BlockchainDailyCache implements IResource {
  idBlockchainDailyCachePk: number = 0

  blockchainVersion: BlockchainVersion | null = null
  contractCount: number | null = null
  addressCount: number | null = null
  transactionCount: number | null = null
  date: string | null = null

  get $id() {
    return this.idBlockchainDailyCachePk
  }
  set $id(val) {
    this.idBlockchainDailyCachePk = val
  }
  get $tag() {
    return String(this.$id)
  }

  /**
   * Gets a instance of a given ID of BlockchainDailyCache
   */
  async getBlockchainDailyCache(id: number) {
    return await Request.get(`/client/blockchain-daily-cache/${id}`)
      .name('getBlockchainDailyCache')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of BlockchainDailyCache
   */
  static async listBlockchainDailyCache(params: any) {
    return await Request.get(`/client/blockchain-daily-cache`, {params})
      .name('listBlockchainDailyCache')
      .as(BlockchainDailyCacheCollection)
      .getData()
  }

  /**
   * Persists a new instance of BlockchainDailyCache. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistBlockchainDailyCache() {
    return await Request.post(`/client/blockchain-daily-cache`, this)
      .name('persistBlockchainDailyCache')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of BlockchainDailyCache to export as a file
   */
  static async listExportBlockchainDailyCache(params: any) {
    return await Request.get(`/client/blockchain-daily-cache/export`, {params})
      .name('listExportBlockchainDailyCache')
      .as(BlockchainDailyCacheCollection)
      .getData()
  }
}
