
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {BlockchainDailyCacheCollection} from '@/model/collection/BlockchainDailyCacheCollection'
import {FilterBlockchainDailyCacheSchema} from '@/schema/resource/BlockchainDailyCache/FilterBlockchainDailyCacheSchema'

@Component
export default class FilterBlockchainDailyCache extends Vue {
  @Prop({type: Object, required: true})
  collection!: BlockchainDailyCacheCollection

  schema = new FilterBlockchainDailyCacheSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = []

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
