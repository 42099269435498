






















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterBlockchainDailyCache from '@/components/filters/FilterBlockchainDailyCache.vue'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'
import {BlockchainDailyCacheCollection} from '@/model/collection/BlockchainDailyCacheCollection'
import {ListBlockchainDailyCacheSchema} from '@/schema/resource/BlockchainDailyCache/ListBlockchainDailyCacheSchema'
import {ExportBlockchainDailyCacheSchema} from '@/schema/resource/BlockchainDailyCache/ExportBlockchainDailyCacheSchema'

@Component({
  components: {FilterToggle, FilterBlockchainDailyCache},
})
export default class ListBlockchainDailyCacheView extends Mixins(
  MixinRouteMatch
) {
  schema = new ListBlockchainDailyCacheSchema()
  collection = new BlockchainDailyCacheCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: BlockchainDailyCache) {
    this.$nav.pushByName('editBlockchainDailyCache', item.$id)
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new BlockchainDailyCacheCollection()
      .clearFilters()
      .addFilter(params)

    await coll.listExportBlockchainDailyCache()
    this.$xlsx.downloadFromSchema(
      coll.items,
      new ExportBlockchainDailyCacheSchema()
    )
  }
}
