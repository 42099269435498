/**
 * List Schema of BlockchainDailyCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'

/* TODO: review generated schema */
export class ListBlockchainDailyCacheSchema extends DefaultSchema {
  readonly name = 'ListBlockchainDailyCache'

  readonly fieldSet: FieldSet<BlockchainDailyCache> = {
    idBlockchainDailyCachePk: (): FieldComponent => ({
      is: Component.Render,
    }),
    contractCount: (): FieldComponent => ({
      is: Component.Render,
    }),
    addressCount: (): FieldComponent => ({
      is: Component.Render,
    }),
    transactionCount: (): FieldComponent => ({
      is: Component.Render,
    }),
    date: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.datetime(schema.model.date),
      },
    }),
  }
}
