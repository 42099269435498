/**
 * Filter Schema of BlockchainDailyCache
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IBlockchainDailyCacheCollectionResourcesHolder} from '@/model/collection/BlockchainDailyCacheCollection'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'

/* TODO: review generated schema */
export class FilterBlockchainDailyCacheSchema extends DefaultSchema
  implements IBlockchainDailyCacheCollectionResourcesHolder {
  readonly name = 'FilterBlockchainDailyCache'

  readonly fieldSet: FieldSet<BlockchainDailyCache> = {
    startDate: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'mask',
        maskPreset: 'date',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    endDate: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'mask',
        maskPreset: 'date',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    minContractCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    maxContractCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    minAddressCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    maxAddressCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    minTransactionCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    maxTransactionCount: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
